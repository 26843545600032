import * as React from "react";

import { CategoryDivision, CategoryDivisionDisplayName } from "../../models/CategoryDivision";
import { CellContext, ColumnDef, ColumnDefBase, ExpandedState, RowData, SortingState, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { FiltersAction, FiltersView } from "../../reducers/filterReducer";
import { FontIcon, Icon, IconButton, PrimaryButton, SpinButton, Stack, TextField } from "@fluentui/react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getLastFiscalYear, suppleBudgets } from "../../utils/BudgetUtils";
import moment, { now } from "moment";
import { useGetLatestBudgetVersion, useGetSubmitOverview, useInitializeNewFinOpsBudgets } from "../../hooks/useFinOpsBudget";

import { BudgetLayout } from "./BudgetLayout";
import { IAppState } from "../../store";
import { IFinOpsBudget } from "../../models/FinOpsBudgets";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { ServiceTreeLevel } from "../../models/serviceTree";
import WorkloadFilter from "../common/FiltersBanner/WorkloadFilter/WorkloadFilter";
import styles from "./Budget.less";
import { sum, toNumber } from "lodash";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { Endpoints } from "../../utils/Constants";

declare module '@tanstack/react-table' {
    interface TableMeta<TData extends RowData> {
      updateData: (rowIndex: number, columnId: string, value: unknown) => void
    }

    interface ColorKeyColumnBase<TData extends RowData, TValue = unknown> extends ColumnDefBase<TData, TValue> {
        headerStyles: React.CSSProperties;
    }
}
  
// Give our default column cell renderer editing superpowers!
const numberCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    const updateTable = (rowId: number, cloumnId: string, value: number) => {
        let newIncreaseRate = 0;
        let newQuantityBudget = 0;
        let newCostBudget = 0;
        if (cloumnId === 'increaseRate') {
            newIncreaseRate = value;
            newQuantityBudget = cell.row.original.lastYearQuantity * (100 + value) / 100;
            newCostBudget = cell.row.original.lastYearCost * (100 + value) / 100;
        } else if (cloumnId === 'quantityBudget') {
            newIncreaseRate = cell.row.original.lastYearQuantity ? (value - cell.row.original.lastYearQuantity) / cell.row.original.lastYearQuantity * 100 : 0;
            newQuantityBudget = value;
            newCostBudget = newIncreaseRate * cell.row.original.lastYearCost / cell.row.original.lastYearQuantity / 100;
        } else if (cloumnId === 'costBudget') {
            newIncreaseRate = cell.row.original.lastYearCost ? (value - cell.row.original.lastYearCost) / cell.row.original.lastYearCost * 100 : 0;
            newQuantityBudget = cell.row.original.lastYearQuantity * (100 + newIncreaseRate) / 100;
            newCostBudget = value;
        } else {
            return;
        }
        cell.table.options.meta?.updateData(rowId, 'increaseRate', parseFloat(newIncreaseRate.toFixed(2)));
        cell.table.options.meta?.updateData(rowId, 'quantityBudget', parseFloat(newQuantityBudget.toFixed(2)));
        cell.table.options.meta?.updateData(rowId, 'costBudget', parseFloat(newCostBudget.toFixed(2)));
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <SpinButton
        value = {(value === undefined ? 0 : value).toString()}
        step = {0.1}
        onChange={(_e, value) => value && updateTable(cell.row.index, cell.column.id, toNumber(value))}
        />
    );
};
const textCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    const updateTable = (rowId: number, columnId: string, value: string) => {
        cell.table.options.meta?.updateData(rowId, columnId, value);
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <TextField
            multiline
            value={(value || '').toString()}
            onChange={(_e, value) => value && updateTable(cell.row.index, cell.column.id, value)}
        />
    );
};

const DEFAULT_FISCAL_YEAR = 'FY26';

export const Budget: React.FC = () => {
    const [editing, setEditing] = React.useState(false);
    const currentFiscalYears = useCategoryFilters().filters.filters.FiscalYear;
    const currentFiscalYear: string = (currentFiscalYears && currentFiscalYears.length > 0 ? currentFiscalYears[0] : '');
    const updateFilters = useCategoryFilters().updateFilters;
    React.useEffect(() => {
        if (!currentFiscalYear) {
            updateFilters(
                FiltersAction.Add,
                {
                    filters: {FiscalYear: [DEFAULT_FISCAL_YEAR]},
                    view: FiltersView.AddableList
                });
        }
    }, [currentFiscalYear]);

    const selectedServices = useCategoryFilters().filters.filters.Service;
    const currentService = selectedServices && selectedServices.length > 0 ? selectedServices[0] : undefined;
    const selectedOwners = useCategoryFilters().filters.filters.Owner;
    const currentOwner = selectedOwners && selectedOwners.length > 0 ? selectedOwners[0] : undefined;
    const {data: initialData, isLoading} = useGetSubmitOverview({
      fiscalYear: currentFiscalYear,
      serviceId: currentService,
      serviceGM: currentOwner
    }, moment('2024-7-1'), moment('2025-6-30'));
    const notSubmitted = React.useMemo(() => initialData?.every(item => !item.submitter), [initialData]);

    const columns = React.useMemo<ColumnDef<IFinOpsBudget, number | string>[]>(
        () => {
            let baseColumns: ColumnDef<IFinOpsBudget, number | string | undefined>[] = [
                {
                    id: 'category',
                    header: 'Category',
                    accessorKey: 'category',
                    cell: ({row, getValue}) => (
                        <>
                        {getValue()}
                        </>
                    ),
                    footer: 'Total',
                },
                {
                    id: 'type',
                    header: 'Type',
                    accessorKey: 'type',
                },
                {
                    id: 'spend',
                    header: `${getLastFiscalYear(currentFiscalYear)} Spend`,
                    accessorKey: 'lastYearCost',
                    footer: ({table}) => {
                        return sum(table.getFilteredRowModel().rows.map(row => row.original.lastYearCost));
                    }
                },
                {
                    id: 'quantity',
                    header: `${getLastFiscalYear(currentFiscalYear)} Quantity`,
                    accessorKey: 'lastYearQuantity'
                },
                {
                    id: 'unit',
                    header: 'Unit',
                    accessorKey: 'unit',
                },
                {
                    id: 'blendedRate',
                    header: 'Blended Rate',
                    accessorFn: row => {
                        let n = row.lastYearCost / (row.lastYearQuantity || 1);
                        const numberBeforeDecimal = Math.floor(n);
                        n -= numberBeforeDecimal;
                        if (n === 0) {
                            return numberBeforeDecimal;
                        }
                        return parseFloat(numberBeforeDecimal + n.toFixed(1-Math.floor(Math.log(n)/Math.log(10))));
                    },
                }];
            // Only show budget info when editing or the budget is not submitted
            if (editing || !notSubmitted) {
                baseColumns = baseColumns.concat([{
                    id: 'increaseRate',
                    header: `${currentFiscalYear} Increase Rate`,
                    accessorKey: 'increaseRate',
                    cell: !editing ? cell => cell.getValue() : numberCellFormatter,
                },
                {
                    id: 'costBudget',
                    header: `${currentFiscalYear} Cost`,
                    accessorKey: 'costBudget',
                    cell: !editing ? cell => cell.getValue() : numberCellFormatter,
                    footer: ({table}) => {
                        return sum(table.getFilteredRowModel().rows.map(row => row.original.costBudget));
                    }
                },
                {
                    id: 'quantityBudget',
                    header: `${currentFiscalYear} Quantity`,
                    accessorKey: 'quantityBudget',
                    cell: !editing ? cell => cell.getValue() : numberCellFormatter,
                },
                {
                    id: 'justification',
                    header: 'Justification',
                    accessorKey: 'justification',
                    cell: !editing ? cell => cell.getValue() : textCellFormatter,
                }]);
            }

            // Only show review info when not editing and the budget is submitted
            if (!editing && !notSubmitted) {
                baseColumns = baseColumns.concat(
                    [{
                    id: 'submitter',
                    header: 'Submitter',
                    accessorKey: 'submitter',
                    },
                    {
                        id: 'version',
                        header: 'Version',
                        accessorFn: row => row.version?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'approver',
                        header: 'Approver',
                        accessorKey: 'approver',
                    },
                    {
                        id: 'approved',
                        header: 'Status',
                        accessorKey: 'approved',
                        cell: 
                            cell => cell.getValue() == 2 ? 'Yes': cell.getValue() == 1 ? 'No' : 'Not reviewed',
                    },
                    {
                        id: 'approvedTime',
                        header: 'Approved Time',
                        accessorFn: row => row.approvedTime?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'comment',
                        header: 'Comment',
                        accessorKey: 'comment',
                        cell: cell => cell.getValue(),
                    },
                ]);
            }

            return baseColumns;
        },
        [editing, notSubmitted, currentFiscalYear]
      );
      const [data, setData] = React.useState(initialData || []);
      React.useEffect(() => {
        setEditing(false);
      }, [currentService]);
      React.useEffect(() => {
        if (!isLoading)
            setData(editing ? suppleBudgets(
                currentFiscalYear,
                currentService || '',
                initialData || []) :
                initialData || []);
      }, [isLoading, editing]);

      const [sorting, setSorting] = React.useState<SortingState>([]);
    
      const table = useReactTable({
        data,
        columns,
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getSubRows: row => [],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        // Provide our updateData function to our table meta
        meta: {
          updateData: (rowIndex, columnId, value) => {
            setData(old =>
              old.map((row, index) => {
                if (index === rowIndex) {
                  return {
                    ...old[rowIndex]!,
                    [columnId]: value,
                  }
                }
                return row
              })
            )
          },
        },
      });

      const filter = CategoryDivision.Service;
      const ownerFilter = CategoryDivision.Owner;
      const fiscalYearFilter = CategoryDivision.FiscalYear;
      const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
      const serviceIdList = Array.from(serviceTree.indexMap).filter(item => item[1].l == ServiceTreeLevel.Service).map(item => item[1].n)
      const getPlatformServiceLabel = React.useCallback(
        (key: string) => {
            return serviceTree.indexMap.get(key)?.n || key;
        },
        [serviceTree.indexMap]
      );
      const onwerList = Array.from(serviceTree.owners.keys());
      const fiscalYearList = ['FY26']; //Available fiscal years

      const queryClient = useQueryClient();
      const mutation = useInitializeNewFinOpsBudgets(
        () => {
            queryClient.invalidateQueries([Endpoints.GetLatestBudgetVersion]);
            setEditing(false);
        },
        () => {
            alert('Failed to submit');
        }
      );

      return (
        <BudgetLayout>
            <h1>Submit Budget</h1>
            <Stack horizontal tokens={{childrenGap: 20}}>
            <WorkloadFilter
                key={filter}
                category={filter}
                optionList={serviceIdList}
                displayName={CategoryDivisionDisplayName[filter]}
                getCheckboxLabel={getPlatformServiceLabel}
                isSingleSelect
            />
            <WorkloadFilter
                key={ownerFilter}
                category={ownerFilter}
                optionList={onwerList}
                displayName={CategoryDivisionDisplayName[ownerFilter]}
                isSingleSelect
            />
            <WorkloadFilter
                key={fiscalYearFilter}
                category={fiscalYearFilter}
                optionList={fiscalYearList}
                displayName={CategoryDivisionDisplayName[fiscalYearFilter]}
                isSingleSelect
            />
            {
                currentService && (
                    <PrimaryButton
                        text="Create buget for this service"
                        onClick={() => setEditing(true)} />
                )
            }
            </Stack>
            <Table>
                <TableHead className={styles.tableHeader}>
                    {
                        table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header => (
                                        <TableCell key={header.id} colSpan={header.colSpan}
                                            className={header.column.id == 'category' ? styles.categoryHeader :
                                                header.column.id == 'type' ? styles.typeHeader : ''
                                            }
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {header.isPlaceholder ? null : (
                                                flexRender(header.column.columnDef.header, header.getContext())
                                            )}
                                            {
                                                {
                                                    asc: <FontIcon iconName='SortUp'/>,
                                                    desc: <FontIcon iconName='SortDown'/>
                                                }[header.column.getIsSorted() as string] ?? null
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map(row => {
                    return (
                        <TableRow key={row.id} className={styles.tableRow}>
                        {row.getVisibleCells().map(cell => {
                            return (
                            <TableCell key={cell.id}
                                className={cell.column.id == 'category' ? styles.categoryColumn :
                                    cell.column.id == 'type' ? styles.typeColumn : ''
                                }>
                                {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                                )}
                            </TableCell>
                            )
                        })}
                        </TableRow>
                    )
                    })}
                    {
                        table.getFooterGroups().map(footerGroup => (
                            <TableRow key={footerGroup.id} className={styles.tableFooter}>
                                {
                                    footerGroup.headers.map(header => (
                                        <TableCell key={header.id}
                                            className={header.column.id == 'category' ? styles.categoryFooter :
                                                header.column.id == 'type' ? styles.typeFooter : ''
                                            }>
                                            {header.isPlaceholder ? '-' : (
                                                flexRender(header.column.columnDef.footer, header.getContext())
                                            )}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            {
                editing &&
                <PrimaryButton
                    style={{marginTop: 20}}
                    disabled={mutation.isLoading}
                    onClick={() => {mutation.mutate(data)}}>
                        {mutation.isLoading ? 'Submitting...' : 'Submit'}
                </PrimaryButton>
            }
        </BudgetLayout>
      )
}