import { IFinOpsBudget, IFinOpsBudgetFilters } from "../models/FinOpsBudgets";
import { getAllHistoricalBudgetVersion, getAllPendingBudgetAsks, getLatestBudgetVersion, getSubmitOverview, initializeNewFinOpsBudgets, reviewFinOpsBudgets, updateFinOpsBudgets } from "../services/FinOpsService";
import { useMutation, useQuery } from "react-query";

import { Endpoints } from "../utils/Constants";
import { IAppState } from "../store";
import { Moment } from "moment";
import { useCategoryFilters } from "./useFilters";
import { useDateRange } from "./useDateSelector";
import { useSelector } from "react-redux";

export function useInitializeNewFinOpsBudgets(
    onSuccess: () => void,
    onError: () => void
) {
    return useMutation({
        mutationFn:(data: IFinOpsBudget[]) => initializeNewFinOpsBudgets(data),
        onSuccess,
        onError
    });
}

export function useUpdateFinOpsBudgets() {
    return useMutation((data: IFinOpsBudget) => updateFinOpsBudgets(data));
}

export function useReviewFinOpsBudgets() {
    return useMutation((data: IFinOpsBudget) => reviewFinOpsBudgets(data));
}

export function useGetAllPendingBudgetAsks(finOpsFilters: IFinOpsBudgetFilters) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();

    return useQuery<IFinOpsBudget[], Error>(
        [Endpoints.GetAllPendingBudgetAsks, globalFilters.filters, range.startDate, range.endDate],
        () => getAllPendingBudgetAsks(finOpsFilters)
    );
}

export function useGetAllHistoricalBudgetVersion(finOpsFilters: IFinOpsBudgetFilters) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();

    return useQuery<IFinOpsBudget[], Error>(
        [Endpoints.GetAllHistoricalBudgetVersion, globalFilters.filters, range.startDate, range.endDate],
        () => getAllHistoricalBudgetVersion(finOpsFilters)
    );
}

export function useGetLatestBudgetVersion(finOpsFilters: IFinOpsBudgetFilters) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();

    return useQuery<IFinOpsBudget[], Error>(
        [Endpoints.GetLatestBudgetVersion, globalFilters.filters, range.startDate, range.endDate],
        () => getLatestBudgetVersion(finOpsFilters)
    );
}

export function useGetSubmitOverview(finOpsFilters: IFinOpsBudgetFilters, startTime:Moment, endTime:Moment) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();

    return useQuery<IFinOpsBudget[], Error>(
        [Endpoints.GetSubmitOverview, globalFilters.filters, range.startDate, range.endDate],
        () => getSubmitOverview(finOpsFilters, startTime, endTime)
    );
}