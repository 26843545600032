import { IFinOpsBudget, IFinOpsBudgetFilters } from "../models/FinOpsBudgets";
import { get, post, postJson } from "../utils/apiServiceBase";
import { isNil, omitBy, orderBy, sortBy } from "lodash";
import moment, { Moment } from "moment";

export async function initializeNewFinOpsBudgets(data: IFinOpsBudget[]): Promise<void> {
    const url = `api/v2.0/finops/initializeNewBudget`;

    try {
        await post(url, data);
        console.log('Budget inserted successfully');
    } catch (error) {
        console.error('Error inserting data:', error);
        throw new Error('Failed to insert budget. ' + error);
    }
}

export async function updateFinOpsBudgets(data: IFinOpsBudget): Promise<void> {
    const url = `api/v2.0/finops/updateExistingBudget`;

    try {
        await post(url, data);
        console.log('Budget updated successfully');
    } catch (error) {
        console.error('Error updating data:', error);
        throw new Error('Failed to update budget. ' + error);
    }
}

export async function reviewFinOpsBudgets(data: IFinOpsBudget): Promise<void> {
    const url = `api/v2.0/finopsAdmin/ReviewBudget`;

    try {
        await post(url, data);
        console.log('Review status updated successfully');
    } catch (error) {
        console.error('Error reviewing data:', error);
        throw new Error('Failed to update review status. ' + error);
    }
}

export async function getAllPendingBudgetAsks(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finopsAdmin/getAllPendingBudgetAsks?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch pending review budget asks: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        console.log('Pending review budget asks retrieved successfully');
        return data;
    } catch (error) {
        console.error('Error fetching pending review budget asks:', error);
        throw new Error('Failed to fetch pending review budget asks. ' + error);
    }
}

export async function getAllHistoricalBudgetVersion(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finopsAdmin/getAllHistoricalBudgetVersion?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch historical budget versions: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        console.log('Historical budget versions retrieved successfully');
        return data;
    } catch (error) {
        console.error('Error fetching historical budget versions:', error);
        throw new Error('Failed to fetch historical budget versions. ' + error);
    }
}

export async function getLatestBudgetVersion(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finops/getLatestBudgetVersion?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch latest budget version: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        data.forEach(budget => {
            if (!budget.submitter) {
                budget.version = undefined;
                budget.approvedTime = undefined;
            } else {
                budget.version = moment(budget.version);
                budget.approvedTime = moment(budget.approvedTime);
            }
        })
        console.log('Latest budget version retrieved successfully');
        return data;
    } catch (error) {
        console.error('Error fetching latest budget version:', error);
        throw new Error('Failed to fetch latest budget version. ' + error);
    }
}

export async function getSubmitOverview(finOpsFilters: IFinOpsBudgetFilters, startTime:Moment, endTime:Moment): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finops/getSubmitOverview?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}&startTime=${startTime.format('YYYY-MM-DD')}&endTime=${endTime.format('YYYY-MM-DD')}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch submit overview: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        data.forEach(budget => {
            // If the budget is not submitted, set the default increase rate to 4% and calculate the budget based on last year's cost and quantity
            if (budget.increaseRate === 0
                && budget.quantityBudget === 0
                && budget.costBudget === 0) {
                budget.increaseRate = 4;
                budget.costBudget = parseFloat((budget.lastYearCost * 1.04).toFixed(2));
                budget.quantityBudget = parseFloat((budget.lastYearQuantity * 1.04).toFixed(2));
            }

            if (!budget.submitter) {
                budget.version = undefined;
                budget.approvedTime = undefined;
            } else {
                budget.version = moment(budget.version);
                budget.approvedTime = moment(budget.approvedTime);
            }
        })
        console.log('Submit overview retrieved successfully');
        return orderBy(data, 'lastYearCost', 'desc');
    } catch (error) {
        console.error('Error fetching submit overview:', error);
        throw new Error('Failed to fetch submit overview. ' + error);
    }
}